<template>
  <div class="about">
     <el-button size="small" @click="AddRole()">新增</el-button>
    <br />
    <br />
    <el-table height="500px" :data="RoleDatas.Datas" border style="width: 100%">
      <el-table-column label="名称" width="180">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.Name }}</span>
          </div>
        </template>
      </el-table-column>
        <el-table-column label="简介" width="300">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.Detail }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="300px">
        <template #default="scope">
          <el-button
            type="button"
            size="small"
            @click="EditWindow(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            @click="DeleteRole(scope.$index, scope.row)"
            >删除</el-button
          >

        </template>
      </el-table-column>
    </el-table>
  </div>

  <!-- 修改新增分类 -->
  <el-dialog
    v-model="EditOrAddWindow"
    :title="IsAdd ? '新增' : '修改'"
    width="80%"
    :before-close="EditOrAddWindowClose"
  >
    <el-scrollbar height="400px">
      <el-form ref="form" label-position="right" label-width="100px">
        <el-row gutter="30" class="dialog-manages-rowwidth">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="名称">
              <el-input v-model="RoleModel.Name"></el-input>
            </el-form-item>
            <el-form-item label="介绍">
              <el-input
                v-model="RoleModel.Detail"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="EditOrAddWindowClose">关闭</el-button>
        <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
      </span>
    </template>
  </el-dialog>

</template>
<script  >
import axios from "../../../commons/AxiosMethod.js"
import { ElMessageBox, ElSelect } from "element-plus"

import { Plus, ZoomIn, Download, Delete } from "@element-plus/icons-vue" 
export default {
  data () {
    return {
      IsAdd: false,
      EditOrAddWindow: false,
      RoleDatas: [],
      RoleModel: {

      },
      imageUrl: null,
      fileList: [],
      UploadImageUrl: "",
      headersImage: "",
      dialogImageUrl: "",
      dialogVisible: false,

      SelectCollectionOpts: [], // 筛选条件
      TimesRange: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23
      ],
      OptionsTime: [],
      CollectionTypes: []
    }
  },
  name: "App",

  components: {
    Plus,
    ZoomIn,
    Download,
    Delete
  },
  mounted () {
    this.GetRole()

    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"

    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }
  },
  methods: {
    AddCategoryTag (val) {
      console.log(val)
    },
    RemoveCategoryTag (val) {
      console.log(val)
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 上传成功操作
    UploadSuccess (response, file, fileList) {
      if (response.Code == 200) {
        this.CollectionModel.Images = response.Data.FileName
      }
    },
    // 上传文件前操作
    UploadImage (file) {
      const ispng = file.type === "image/png"
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!ispng) {
        ElMessage.error("请上传png格式文件")
        return false
      }

      if (!isLt2M) {
        ElMessage.error("请上传2M以内文件")
        return false
      }
    },
    handleRemove (file) {
      this.fileList = []
      this.CollectionModel.Images = ""
    },

    EditOrAddWindowClose () {
      this.EditOrAddWindow = false
    },
    // 打开编辑页面
    EditWindow (index, item) {
      this.EditOrAddWindow = true
      this.RoleModel = item
    },
    DeleteRole (index, item) {
      ElMessageBox.confirm("确定要删除" + item.Name + "吗？")
        .then(() => {
          axios.apiMethod(
            "/admins/Role/DeleteRole?Id=" + item.Id,
            "get",
            {},
            (result) => {
              if (result.Code == 200) {
                this.EditOrAddWindow = false
                this.GetRole()
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    AddRole () {
      this.RoleModel = { }
      this.EditOrAddWindow = true
      this.IsAdd = true
    },
    GetRole () {
      var param = { Where: {}}
      axios.apiMethod("/admins/Role/GetList", "post", param, (result) => {
        if (result.Code == 200) {
          this.RoleDatas = result.Data
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    },
    // 保存或者修改
    EditOrAddSave () {
      axios.apiMethod(
        "/admins/Role/UpdateOrAdd",
        "post",
        this.RoleModel,
        (result) => {
          if (result.Code == 200) {
            this.GetRole()
            this.EditOrAddWindowClose()
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    AccountRoleWindow () {

    }
  }
}
</script>

<style scoped>
.manage_title {
  height: 50px;
  background-color: rgb(67, 74, 80);
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.dialog-manages-height {
  height: 400px;
  overflow: auto;
  width: 95%;
  padding: 10px;
}

.dialog-manages-rowwidth {
  width: 95% !important;
}
</style>
